import React from "react"
import { graphql } from "gatsby"
import Layout from "src/component/Layout"
import AlbumPage from "src/component/AlbumPage"

// type TitledUrl = { title: string; url?: string }

export default ({
  data: {
    imagesByAlbum: { edges: images },
  },
  pageContext: { album: albumName, gallery: galleryName, next, previous },
}) => {
  // const album = { title: albumName, url: "/portfolio/calendar" }
  const album: TitledUrl = { title: albumName }
  const gallery: TitledUrl = { title: galleryName, url: `/${galleryName}` }
  return (
    <Layout album={album} gallery={gallery}>
      <AlbumPage images={images} nearby={{ next, previous }} />
    </Layout>
  )
}

// query ImagesByAlbum {
//   imagesByAlbum: allFile(
//     filter: {
//       sourceInstanceName: { eq: "albums" }
//       relativeDirectory: { eq: "calendar" }
//       internal: { mediaType: { eq: "image/jpeg" } }
//     }
//   ) {

export const pageQuery = graphql`
  query ImagesByAlbum($gallery: String, $album: String) {
    imagesByAlbum: allFile(
      filter: {
        sourceInstanceName: { eq: $gallery }
        relativeDirectory: { eq: $album }
        internal: { mediaType: { eq: "image/jpeg" } }
      }
    ) {
      edges {
        node {
          id
          name
          fullFluid: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

// thumb: childImageSharp {
//   fixed(width: 50) {
//     ...GatsbyImageSharpFixed
//   }
// }
// fullFixed: childImageSharp {
//   fixed {
//     ...GatsbyImageSharpFixed
//   }
// }

// export const pageQuery = graphql`
//   query MyQuery {
//     albums: allDirectory(
//       filter: { sourceInstanceName: { eq: "albums" }, relativePath: { ne: "" } }
//     ) {
//       edges {
//         node {
//           name
//           cover {
//             childImageSharp {
//               fixed(width: 180) {
//                 ...GatsbyImageSharpFixed
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
