import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import css from "./style.module.css"

export default ({ images, nearby: { next, previous } }) => (
  <div className={css.page}>
    <div className={css.nearby}>
      {previous && (
        <Link className={css.nearby_previous} to={previous?.slug}>
          {/* {"< "} */}
          {previous?.name}
        </Link>
      )}
      {next && (
        <Link className={css.nearby_next} to={next?.slug}>
          {next?.name}
          {/* {" >"} */}
        </Link>
      )}
    </div>
    <div className={css.content}>
      {images.map(({ node: { id, name, fullFixed, fullFluid } }) => (
        <Link key={id} className={css.image} to={`#zz${id}`}>
          {/* <Img fixed={thumb.fixed} /> */}
          <Img fluid={fullFluid.fluid} />
          {/* <Img fixed={fullFixed.fixed} /> */}
          <div className={css.imageName}>{name}</div>
        </Link>
      ))}
    </div>
  </div>
)
